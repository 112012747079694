<template>
    <div style="max-width: 100% !important;">
        <v-skeleton-loader
            :loading="loading"
            type="table-row@3"
        >
            <v-subheader>{{ title }}</v-subheader>
            <v-data-table dense :headers="tableHeaders" :items="tableData" item-key="uic"></v-data-table>
        </v-skeleton-loader>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { listsStore } from '../../stores/lists-store';

export default {
    name: 'TopAuthorsReport',
    props: {
        stats: Array,
        authors: Array,
        loading: Boolean,
        title: String,
        type:  String,
    },
    data: () => ({
        panelTitle: "",
        show: true,
        lists: listsStore(),

        tableHeaders: [
            {text: "Autor", value: 'name'},
            {text: "-", value: 'q'},
        ],
        tableData: [],
    }),
    watch: {
        stats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        this.prep();
    },
    methods: {
        prep: function() {
            
            this.tableData = [];
            let tableDataTmp = [];
            
            let years = [];
            if(this.stats) {

                this.stats.forEach( pubStat => {
                    
                    let pubInfo = this.authors.find( p => p.uic == pubStat)

                    let tmpItem = {
                        uic: pubStat,
                        name: pubInfo.author.displayName,
                        q: this.getData(pubInfo.total),
                    }
                    
                    tableDataTmp.push(tmpItem);
                });

                console.log("top pubs", tableDataTmp);
            }
            
            this.tableData = tableDataTmp;
        },
        getData: function( item ) {
            console.log("data", item)
            switch (this.type) {
                case "total":
                    return item.totalCount;
                    break;
                case "first":
                    return item.firstCount;
                    break;
                case "second":
                    return item.secondCount;
                    break;
                case "score":
                    return item.score;
                    break;
            }

            return 0;
        },
       
    }

}
</script>
