<template>
    <v-container fluid>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Typ</th>
                        <th>Popis</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="resItem in checkResult.results" :key="resItem.type">
                        <td>
                            <v-icon v-if="!resItem.success" color="red">mdi-alert-circle</v-icon>
                            <v-icon v-if="resItem.success" color="green">mdi-check-circle</v-icon>
                        </td>
                        <td>{{ $t("check.type." + resItem.type) }}</td>
                        <td>
                            <span v-if="!resItem.success">{{ $t("check.results." + resItem.code ) }}</span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
import PublicationTagIcon from './PublicationTagIcon.vue';
import PublicationService from '../../services/publication-service';
import AuthorAvatar from './AuthorAvatar.vue';

export default {
    name: "PublicationCheckResults",
    props: {
        publicationId: [Number,String],
    },
    data: function () {
        return {
            color: "",
            icon: "",
            publicationService: new PublicationService(),
            checkResult: {},
            registry: [
                {type: "doi"}
            ]
        };
    },
    mounted: function () {
        this.fetchLog()
    },
    watch: {
        publicationId: function(nV,oV) {
            console.log("check results change")
            this.fetchLog();
        }
    },
    methods: {
        fetchLog: function() {
            console.log("fetch log")

            if(this.publicationId <= 0) {
                return;
            }

            this.publicationService.fetchCheckResults(this.publicationId)
                .then( r => {
                    this.checkResult = r.data;
                } )
        },
    },
}
</script>