<template>
    <div style="max-width: 100% !important;">
        <v-skeleton-loader
            :loading="loading"
            type="table-row"
        >
            <v-subheader>Ford</v-subheader>
            <v-data-table dense :headers="tableHeaders" :items="tableData" item-key="id"></v-data-table>
        </v-skeleton-loader>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { listsStore } from '../../stores/lists-store';

export default {
    name: 'FordAreasListReport',
    props: {
        stats: Object,
        loading: Boolean
    },
    data: () => ({
        panelTitle: "",
        show: true,
        lists: listsStore(),

        tableHeaders: [
            {text: "Ford", value: 'name'},
            {text: "Počet", value: 'count'},
        ],
        tableData: [],
    }),
    watch: {
        stats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        this.prep();
    },
    methods: {
        prep: function() {
            
            this.tableData = [];
            let tableDataTmp = [];
            this.tableHeaders = [
                {text: "Ford", value: 'name'},
            ];
            
            let years = [];

            if(this.stats) {
                Object.entries(this.stats).forEach( ([y, d]) => {
                    if(years.indexOf(y) == -1) {
                        years.push(y);
                        this.tableHeaders.push({text: y, value: y, align: "center"})
                    }

                    Object.entries(d).forEach( ([eid, evalue]) => {
                        let tabItem = tableDataTmp.find( td => td.id == eid );
                        if(!tabItem) {
                            tabItem = {id: eid, name: eid}
                            tableDataTmp.push(tabItem);
                        }
                        
                        tabItem[y] = evalue
                    } )
                } )
            }

            /*
            // fill names
            tableDataTmp.forEach( t => {
                let fordInfo = this.lists.fordList.find( x => x.value == t.id );
                if(fordInfo) {
                    t.name = fordInfo.text;
                }
            } )
            */
            
            console.log("gen data", tableDataTmp)
            this.tableData = tableDataTmp;
            //console.log("graph", xaxis, data)
        }
    }

}
</script>
