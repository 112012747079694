<template>
        <v-row>
            <v-col cols="8">
                <v-card-title>Publikační záznamy - Modul 1</v-card-title>

                <v-data-table :headers="tableHeaders" :items="pubs" 
                    :items-per-page="50"
                    item-key="id"
                    >
                    <template v-slot:item.state="{item}">
						<PublicationState :stateId="item.publicationStateId" :label="item.publicationState"></PublicationState>
					</template>
					<template v-slot:item.type="{item}">
						<v-chip small>{{ item.publicationTypeShort }}</v-chip>
					</template>
                </v-data-table>
            </v-col>
            <v-col cols="4">
                <ArticleQuartileGraph :loading="loading" :stats="groupStats.journalQuartiles"></ArticleQuartileGraph>
                <ArticleSubTypeGraph :loading="loading" :stats="groupStats.journalTypes"></ArticleSubTypeGraph>
                <TopAuthorsReport title="Nejvíce publikující autoři" :loading="loading" :stats="groupStats.topAuthors" :authors="authorGroupStats" type="total"></TopAuthorsReport>
                <TopAuthorsReport title="Autoři s nejlepšími články" :loading="loading" :stats="groupStats.topScoreAuthors" :authors="authorGroupStats" type="score"></TopAuthorsReport>
                <TopAuthorsReport title="Nejčastější první autoři" :loading="loading" :stats="groupStats.topFirstAuthors" :authors="authorGroupStats" type="first"></TopAuthorsReport>
            </v-col>
        </v-row>

</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { listsStore } from '../../stores/lists-store';
import TopAuthorsReport from './TopAuthorsReport.vue';
import PublicationState from '../parts/PublicationState.vue';
import ArticleQuartileGraph from '../graphs/ArticleQuartileGraph.vue';
import ArticleSubTypeGraph from '../graphs/ArticleSubTypeGraph.vue';

export default {
    name: 'ArticleModuleDashboard',
    components: {
        TopAuthorsReport, PublicationState, ArticleQuartileGraph, ArticleSubTypeGraph
    },
    props: {
        authorStats: Array,
        groupStats: Object,
        authorGroupStats: Array,
        loading: Boolean
    },
    data: () => ({
        panelTitle: "",
        show: true,
        lists: listsStore(), 
        tableHeaders: [
            {text: "Název", value: "name"},
            {text: "Typ", value: "type"},
            {text: "Stav", value: "state"},
            {text: "Rok", value: "yearOfPublication"},
            {text: "AIS-Q", value: "metrics.primaryFordMetric.label"},
            {text: "AIS", value: "metrics.journalMetrics.influenceArticle"},

        ],
        pubs: []
    }),
    watch: {
        groupStats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        this.prep();
    },
    methods: {
        prep: function() {
            
            this.pubs = [];
            let pubs = [];

            if(this.groupStats) {
                this.pubs = this.groupStats.pubs.filter( p => p.moduleId == 1 );
            }
        }  
    }

}
</script>
