<template>
    <div style="max-width: 100% !important;">
        <v-skeleton-loader
            :loading="loading"
            type="table-row@3"
        >
            <v-subheader>Nejvýznamější publikace dle AIS-Q</v-subheader>
            <v-data-table dense :headers="tableHeaders" :items="tableData" item-key="id"></v-data-table>
        </v-skeleton-loader>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { listsStore } from '../../stores/lists-store';

export default {
    name: 'TopPublicationsReport',
    props: {
        stats: Array,
        publications: Array,
        loading: Boolean
    },
    data: () => ({
        panelTitle: "",
        show: true,
        lists: listsStore(),

        tableHeaders: [
            {text: "Název", value: 'name'},
            {text: "Rok", value: 'year'},
            {text: "Kvartil", value: 'q'},
        ],
        tableData: [],
    }),
    watch: {
        stats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        this.prep();
    },
    methods: {
        prep: function() {
            
            this.tableData = [];
            let tableDataTmp = [];
            
            let years = [];
            if(this.stats) {

                this.stats.forEach( pubStat => {
                    
                    let pubInfo = this.publications.find( p => p.id == pubStat)

                    let tmpItem = {
                        id: pubStat,
                        name: pubInfo.name,
                        year: pubInfo.yearOfPublication,
                        q: pubInfo.metrics.primaryFordMetric.label,
                    }
                    
                    tableDataTmp.push(tmpItem);
                });

                console.log("top pubs", tableDataTmp);
            }
            
            this.tableData = tableDataTmp;
        }
    }

}
</script>
