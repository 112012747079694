<template>
    <v-container fluid>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th></th>
                        <th>Tag</th>
                        <th>Datum</th>
                        <th>Uživatel</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tag in tags" :key="tag.id">
                        <td>
                            <PublicationTagIcon :tag="tag"></PublicationTagIcon>
                        </td>
                        <td>{{ tag.name }}</td>
                        <td>{{ tag.date }}</td>
                        <td>
                            <AuthorAvatar :uic="tag.ownerUic"></AuthorAvatar>
                        </td>
                        <td>
                            <v-btn v-if="tag.tagId==9" @click="openCheckLogDetail()" small>Detail</v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
import { authStore } from '@/stores/user'
import PublicationTagIcon from './PublicationTagIcon.vue';
import PublicationService from '../../services/publication-service';
import AuthorAvatar from './AuthorAvatar.vue';
import emitter from '../../plugins/mitt';

export default {
    name: "PublicationTagList",
    props: {
        publicationId: [Number,String],
    },
    data: function () {
        return {
            color: "",
            icon: "",
            userStore: authStore(),
            publicationService: new PublicationService(),
            tags: [],
        };
    },
    computed: {
        pubTags() {
            return this.tags.filter(i => !i.locked ).map(f => {
                f.own = (f.ownerUic == this.userStore.uic);
                return f;
            })
        },
    },
    mounted: function() {
        this.fetchTags()
    },
    watch: {
        publicationId: function(nV,oV) {
            this.fetchTags();
        }
    },
    methods: {
        fetchTags: function() {
            console.log("fetch tags")

            if(this.publicationId <= 0) {
                return;
            }

            this.publicationService.fetchTags(this.publicationId)
                .then(r => {
                    console.log("q", r)
                    this.tags = r.data.data
                })
        },
        tagIcon: function (item) {
            switch (item.code) {
                case "checked":
                    return "mdi-tag-check";
                case "check.valid":
                    return "mdi-tag-check";
                case "check.invalid":
                    return "mdi-alert-circle";
            }
        },
        tagColor: function (item) {
            switch (item.code) {
                case "checked":
                    return "green";
                case "check.valid":
                    return "green";
                case "check.invalid":
                    return "red";
            }
        },
        openCheckLogDetail: function() {
            emitter.emit("publication.check.dialog", {publicationId: this.publicationId, dialogPersist: false} )
        }
    },
    components: { PublicationTagIcon, PublicationTagIcon, AuthorAvatar }
}
</script>