import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class AreaOfEducationService extends BaseService {
    
    async fetchAreas( pid, params, callback )  {
        const response = await axiosInst.get(`/publications/${pid}/areas-of-education`, {
            params: params
        })

        this.handleCallback(callback)
        return response
    }

    async assign( pid, aid, tag, params)  {
        try {
            const response = await axiosInst.put( `/publications/${pid}/areas-of-education/${aid}`, {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async unassign( pid, aid, params)  {
        try {
            const response = await axiosInst.delete( `/publications/${pid}/areas-of-education/${aid}`, {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

    async fetchAreaList( params)  {
        try {
            const response = await axiosInst.get( `/lists/areas-of-education`, {}, {
                params: params
            })
            return response;
        } catch(err) {
            return Promise.reject(err)
        }
    }

}