<template>
	<div>
	<v-card class="pa-4 ma-4">
		<v-card-title>Detail útvaru</v-card-title>
		<v-toolbar dense flat>
			<DepartmentPicker @input="unitPicked" :value="baseUnit.id" :value-text="baseUnit.name"  class="mx-2"></DepartmentPicker>
			<v-select  class="mx-2" v-model="viewYearFrom"
					:items="listsStore.yearList"
					label="Rok od"
					required
					></v-select>
			<v-select  class="mx-2" v-model="viewYearTo"
					:items="listsStore.yearList"
					label="Rok do"
					required
					></v-select>
			<v-btn @click="loadDepartment()" class="mx-2">Zobrazit</v-btn>
			<v-spacer></v-spacer>
			<reload-button-component class="mx-2" v-on:reload="load()" />
		</v-toolbar>
		<v-card-text v-if="unitLoaded">
			<v-divider></v-divider>
			<v-alert type="info">Zobrazované statistiky jsou zatím informativní, ověření přenosti dat ještě probíhá.</v-alert>
			<v-row class="mt-5">
				<v-col cols="6">
					<v-text-field label="Název útvaru" v-model="baseUnit.name" readonly></v-text-field>
				</v-col>
				<v-col>
					<v-text-field label="Zkratka útvaru" v-model="baseUnit.abbr" readonly></v-text-field>
				</v-col>
				<v-col>
					<v-text-field label="Kód útvaru" v-model="baseUnit.code" readonly></v-text-field>
				</v-col>
				<v-col>
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" primary>Export</v-btn>
						</template>
						<v-list>
							<v-list-item link @click="download('text')">
								<v-list-item-title>Uložit jako CSV</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="download('xlsx')">
								<v-list-item-title>Uložit jako Excel (XLSX)</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="exportCitations()">
								<v-list-item-title>Exportovat citace (Word)</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="exportPublications()">
								<v-list-item-title>Exportovat publikace (XLSX)</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-col>
			</v-row>
			<v-tabs v-model="tabIndex">
				<v-tab>Přehled</v-tab>
				<v-tab>Autoři</v-tab>
				<v-tab>Modul 1</v-tab>
				<v-tab>Modul 2</v-tab>
				<v-tab>Modul 3</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabIndex">
				<v-tab-item>
					<v-row>
						<v-col cols="6">
							<TopPublicationsReport :loading="isLoading" :stats="groupStats.topPublications" :publications="groupStats.pubs"></TopPublicationsReport>
							<TopAuthorsReport title="Nejvíce publikující autoři" :loading="isLoading" :stats="groupStats.topAuthors" :authors="authorGroupStats" type="total"></TopAuthorsReport>
							<TopAuthorsReport title="Autoři s nejlepšími články" :loading="isLoading" :stats="groupStats.topScoreAuthors" :authors="authorGroupStats" type="score"></TopAuthorsReport>
						</v-col>
						<v-col cols="3">
							<YearCountGraph :loading="isLoading" :stats="groupStats.totals"></YearCountGraph>
							<PublicationTypeCountGraph :loading="isLoading" :stats="groupStats.publicationTypes"></PublicationTypeCountGraph>
							<ArticleQuartileGraph :loading="isLoading" :stats="groupStats.journalQuartiles"></ArticleQuartileGraph>
							<RootFordCategoriesGraph :loading="isLoading" :stats="groupStats.rootFordCategories"></RootFordCategoriesGraph>
						</v-col>
						<v-col cols="3">
							<PublicationStateCountGraph :loading="isLoading" :stats="groupStats.publicationStates"></PublicationStateCountGraph>
							<ArticleSubTypeGraph :loading="isLoading" :stats="groupStats.journalTypes"></ArticleSubTypeGraph>
							<OpenAccessCountsGraph :loading="isLoading" :stats="groupStats.openAccessTotals"></OpenAccessCountsGraph>
							<RivModulesGraph :loading="isLoading" :stats="groupStats.rivModuleTypes"></RivModulesGraph>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6">
							<TopAuthorsReport title="Nejčastější první autoři" :loading="isLoading" :stats="groupStats.topFirstAuthors" :authors="authorGroupStats" type="first"></TopAuthorsReport>
							<FordAreasListReport :loading="isLoading" :stats="groupStats.fordCategories"></FordAreasListReport>
						</v-col>
						<v-col cols="6">
							<TopAuthorsReport title="Nejčastější druzí autoři" :loading="isLoading" :stats="groupStats.topSecondAuthors" :authors="authorGroupStats" type="second"></TopAuthorsReport>
							<DetailedFordAreasListReport :loading="isLoading" :stats="groupStats.detailedFordCategories"></DetailedFordAreasListReport>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item>
					<AuthorsModuleDashboard :loading="isLoading" :years="years" :authorStats="authorStats" :groupStats="groupStats" :authorGroupStats="authorGroupStats"></AuthorsModuleDashboard>
				</v-tab-item>
				<v-tab-item>
					<ArticleModuleDashboard :loading="isLoading" :authorStats="authorStats" :groupStats="groupStats" :authorGroupStats="authorGroupStats"></ArticleModuleDashboard>
				</v-tab-item>
				<v-tab-item>
					<BiblioModuleDashboard :loading="isLoading" :authorStats="authorStats" :groupStats="groupStats" :authorGroupStats="authorGroupStats"></BiblioModuleDashboard>
				</v-tab-item>
				<v-tab-item>
					<NonBiblioModuleDashboard :loading="isLoading" :authorStats="authorStats" :groupStats="groupStats" :authorGroupStats="authorGroupStats"></NonBiblioModuleDashboard>
				</v-tab-item>
			</v-tabs-items>
		</v-card-text>
	</v-card>
	</div>
</template>

<script> 
import {axiosInst} from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { publicationStore } from '../stores/publication-store'
import { authStore } from '../stores/user'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import { useDepartmentStore } from '../stores/department-store'
import PublicationService from '../services/publication-service'
import YearCountGraph from './graphs/YearCountGraph.vue'
import PublicationTypeCountGraph from './graphs/PublicationTypeCountGraph.vue'
import OpenAccessCountsGraph from './graphs/OpenAccessCountsGraph.vue'
import ArticleSubTypeGraph from './graphs/ArticleSubTypeGraph.vue'
import RootFordCategoriesGraph from './graphs/RootFordCategoriesGraph.vue'
import RivModulesGraph from './graphs/RivModulesGraph.vue'
import FordAreasListReport from './reports/FordAreasListReport.vue'
import DetailedFordAreasListReport from './reports/DetailedFordAreasListReport.vue'
import PublicationStateCountGraph from './graphs/PublicationStateCountGraph.vue'
import ArticleQuartileGraph from './graphs/ArticleQuartileGraph.vue'
import TopPublicationsReport from './reports/TopPublicationsReport.vue'
import TopAuthorsReport from './reports/TopAuthorsReport.vue'
import ArticleModuleDashboard from './reports/ArticleModuleDashboard.vue'
import AuthorsModuleDashboard from './reports/AuthorsModuleDashboard.vue'
import BiblioModuleDashboard from './reports/BiblioModuleDashboard.vue'
import NonBiblioModuleDashboard from './reports/NonBiblioModuleDashboard.vue'


export default {
	components: { ReloadButtonComponent, DepartmentPicker, YearCountGraph, 
		PublicationTypeCountGraph, OpenAccessCountsGraph, ArticleSubTypeGraph,
		RootFordCategoriesGraph, RivModulesGraph, FordAreasListReport, DetailedFordAreasListReport,
		PublicationStateCountGraph, ArticleQuartileGraph, TopPublicationsReport, TopAuthorsReport,
		AuthorsModuleDashboard, ArticleModuleDashboard, BiblioModuleDashboard, NonBiblioModuleDashboard },
	name: 'DepartmentDetail',
	props: {
		panelTitle: {
			type: String,
			default: "Prohlížení záznamů"
		}
	},
	data: () => ({
		uic: 0,
		authorHeaders: [
			{text: "#", value: 'author.uic'},
			{text: "Autor", value: 'author.displayName'},
			{text: "Připraveno", value: 'pending'},
			{text: "Odmítnuto", value: 'rejected'},
			{text: "Schváleno", value: 'approved'},
			{text: "Celkem", value: 'total.totalCount'},
			{text: "Body", value: 'points'},
		],
		query: publicationStore().searchQuery,
		expanded: [],
		listsStore: listsStore(),
		userStore: authStore(),
		departmentStore: useDepartmentStore(),
		publicationService: new PublicationService(),
		pickedPerson: {},
		baseUnit: {},
		department: {},
		unitLoaded: false,
		authorStats: [],
		pubStats: [],
		years: [],
		viewYearFrom: 2024,
		viewYearTo: 2024,
		groupStats: {},
		isLoading: false,
		tabIndex: 0,
		authorGroupStats: [],
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		this.uic = this.$root.getCurrentUser()
		console.log(this.uic)

		//this.unitPicked = {id:16,"name":"Katedra informačních technologií","abbr":"KIT","primary":false,"valid":false};
		//await this.loadDepartment()
		
	},
	methods: {
		genHeaders: function(years) {
			this.authorHeaders = [];
			this.authorHeaders.push({text: "#", value: 'author.uic'});
			this.authorHeaders.push({text: "Autor", value: 'author.displayName'});

			years.forEach( q => {
				this.authorHeaders.push({text: q, value: `total.${q}.totalCount`});

			} )
		},
		load: function() {
			this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: function() {
			this.publicationStore.clear();
			this.publicationStore.fetch( this.query );
		},
		loadDepartment: async function() {
			this.unitLoaded = true;
			this.isLoading = true;
			
			this.baseUnit = await this.departmentStore.get( this.baseUnit.id, {}, null )
			this.pubStats = await this.departmentStore.fetchPubStats( this.baseUnit.id, {year: this.viewYearFrom} );
			let dataDetail = await this.departmentStore.fetchAuthorStats( this.baseUnit.id, {yearFrom: this.viewYearFrom, yearTo: this.viewYearTo} );
			
			this.years = dataDetail.years;
			this.authorStats = dataDetail.data;
			this.groupStats = dataDetail.groupStats;
			this.authorGroupStats = dataDetail.authors;
			console.log("years", dataDetail.years, dataDetail);
			this.genHeaders(dataDetail.years);
			this.isLoading = false;
		},
		personPicked: function(data) {
			if(data) {
				this.pickedPerson = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			}
		},
		unitPicked: function(data) {
			if(data) {
				this.baseUnit = data;
				this.query.baseUnit = data;
				this.query.baseUnitId = data.id;
			}
		},
		exportCitations: function() {
			let query = {
				year: this.viewYear,
				uics: [],
			}

			let uics = this.authorStats.map( f => f.author.uic );
			console.log(uics);
			query.uics = uics;

			this.publicationService.downloadPublicationQueryCitations( query, {format: "docx"})
		},
		exportPublications: function() {
			let query = {
				year: this.viewYear,
				uics: [],
			}

			let uics = this.authorStats.map( f => f.author.uic );
			console.log(uics);
			query.uics = uics;

			this.publicationService.downloadPublicationExport( query, {format: "xlsx"})
		},
		download: function(format) {
			this.departmentStore.downloadAuthorStats(this.baseUnit.id, {year: this.viewYear, format: format})
		}
	},
}

</script>;