<template>
	<v-card class="pa-4 ma-4">
		<v-card-title>{{panelTitle}}</v-card-title>
		<v-toolbar dense flat>
			<PersonPicker @input="personPicked" :value="pickedPerson.uic" :value-text="pickedPerson.displayName" class="mx-2"></PersonPicker>
			<DepartmentPicker @input="unitPicked" :value="baseUnit.id" :value-text="baseUnit.name"  class="mx-2"></DepartmentPicker>
			<v-select class="mx-2" v-model="query.publicationStateId"
					:items="listsStore.publicationStateList"
					label="Stav publikace"
					clearable
					></v-select>
			<v-select  class="mx-2" v-model="query.year"
					:items="listsStore.yearList"
					label="Rok publikace"
					required
					></v-select>
			<v-btn @click="search()" class="mx-2">Hledat</v-btn>
			<v-spacer></v-spacer>
			<reload-button-component class="mx-2" v-on:reload="load()" />
			<v-btn @click="downloadCitations()"><v-icon>mdi-arrow-down</v-icon></v-btn>
		</v-toolbar>
		<v-card-text>
			<v-data-table :headers="headers" :items="publicationStore.items" :items-per-page="tableItemsPerPage">
			
			<template v-slot:item.actions="{item}">
				<v-btn icon @click="removePublication(item)" v-if="userStore.hasRole(['ADMINISTRATOR'])"><v-icon class="mr-2">mdi-trash-can</v-icon></v-btn>
				<v-btn v-if="false" icon :to="{name: 'edit-publication', params: {id: item.id}}"><v-icon class="mr-2">mdi-pencil</v-icon></v-btn>
				<v-btn icon :to="{name: 'publication-detail', params: {id: item.id, backTo: {name: 'publications'}}}"><v-icon class="mr-2">mdi-arrow-right</v-icon></v-btn>
			</template>
			<template v-slot:item.state="{item}">
				<publication-state :stateId="item.publicationStateId" :label="item.publicationState"></publication-state>
			</template>
			<template v-slot:item.type="{item}">
				<v-chip small>{{ item.publicationTypeLabelShort }}</v-chip>
			</template>
			<template v-slot:item.tag="{item}">
				<PublicationTagIcons :tags="item.tags" :publication-id="item.id"></PublicationTagIcons>
			</template>
			</v-data-table>
		</v-card-text>
		<confirm-dialog ref="confirm"></confirm-dialog>
	</v-card>
</template>

<script> 
import {axiosInst} from '../main'
import ReloadButtonComponent from '@/components/ReloadButtonComponent.vue'
import { publicationStore } from '../stores/publication-store'
import PublicationState from '@/components/parts/PublicationState.vue'
import { authStore } from '../stores/user'
import PersonPicker from './parts/PersonPicker.vue'
import DepartmentPicker from './parts/DepartmentPicker.vue'
import { listsStore } from '../stores/lists-store'
import PublicationService from '../services/publication-service'
import ConfirmDialog from './dialogs/ConfirmDialog.vue'
import { useNotificationStore } from '../stores/notification-store'
import PublicationTagIcons from './parts/PublicationTagIcons.vue'

export default {
	components: { ReloadButtonComponent, PersonPicker, DepartmentPicker,PublicationState, ConfirmDialog, PublicationTagIcons },
	name: 'PublicationList',
	props: {
		panelTitle: {
			type: String,
			default: "Prohlížení záznamů"
		}
	},
	data: () => ({
		uic: 0,
		headers: [
			{text: "#", value: 'id'},
			{text: "Název", value: 'name'},
			{text: "Typ", value: 'type'},
			{text: "Stav", value: 'state'},
			{text: "Tag", value: 'tag', sortable: false},
			{text: "Rok", value: 'yearOfPublication'},
			{text: "Akce", value: 'actions', width: "120px", sortable: false},
		],
		publications: [],
		publicationStore: publicationStore(),
		query: publicationStore().searchQuery,
		listsStore: listsStore(),
		userStore: authStore(),
		publicationService: new PublicationService(),
		notificationManager: useNotificationStore(),
		pickedPerson: {},
		baseUnit: {},
		tableItemsPerPage: 15,
		queryKey: "publication-search",
	}),
	mounted: async function() {
		await this.listsStore.fetch();

		this.uic = this.$root.getCurrentUser()
		console.log(this.uic)

		if(this.publicationStore.hasQuery(this.queryKey)) {
			this.query = this.publicationStore.restoreQuery(this.queryKey)
		}

		if(this.query.pickedPerson) {
			this.pickedPerson = this.query.pickedPerson;
		}

		if(this.query.baseUnit) {
			this.baseUnit = this.query.baseUnit;
		}
		/*
		this.$nextTick( () => {
			console.log(this.userStore.uic)
			//this.load();
		});
		*/
	},
	methods: {
		load: function() {
			this.publicationStore.fetch( {uic: this.userStore.uic} )
		},
		search: function() {
			this.publicationStore.storeQuery(this.queryKey, this.query)
			this.publicationStore.clear();

			let qry = {year: this.query.year, uic: this.query.uic, baseUnitId: this.query.baseUnitId};

			if(this.query.publicationStateId > 0) {
				qry.publicationStateId = this.query.publicationStateId;
			}

			if( qry.baseUnitId > 0 || qry.uic > 0 ) {
				this.publicationStore.fetch( qry );
			} else {
				this.notificationManager.addError({message: "Je nutné zadat Autora nebo útvar"})
			}
		},
		personPicked: function(data) {
			console.log("person picked", data)
			if(data) {
				this.pickedPerson = data;
				this.query.pickedPerson = data;
				this.query.uic = data.uic;
			} else {
				this.pickedPerson = {}
				this.query.pickedPerson = {}
				this.query.uic = 0;
			}
		},
		unitPicked: function(data) {
			console.log(data);
			if(data) {
				this.baseUnit = data;
				this.query.baseUnit = data;
				this.query.baseUnitId = data.id;
			} else {
				this.baseUnit = {}
				this.query.baseUnit = {}
				this.query.baseUnitId = 0;
			}
		},
		downloadCitations: function() {
			this.publicationService.downloadFetchCitation( {year: this.query.year, uic: this.query.uic, baseUnitId: this.query.baseUnitId} );
		},
		removePublication: function(item) {
			this.$refs.confirm.open("Potvrzení smazání publikace", `Opravdu chcete smazat publikaci ${item.name}?`, {})
			.then( (c) => {
				this.publicationService.remove(item.id, {})
				.then( (r) => {
					this.load();
					this.notificationManager.addSuccess({message: `Publikace ${item.name} odebrána.`})
				})
			} );
		}
	},
}

</script>;