<template>
    <div style="max-width: 100% !important;">
        <v-skeleton-loader
            :loading="loading"
            type="card"
        >
        <apexchart style="width: 100% !important;" :height="height"  type="bar" :options="lineChartOpts" :series="chartSeries"></apexchart>
        </v-skeleton-loader>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'YearCountGraph',
    props: {
        stats: Object,
        loading: Boolean
    },
    data: () => ({
        panelTitle: "",
        show: true,

        defaultHeight: 200,
        height: 200,

        lineChartOptsDefault: {
            title: {text: 'Celkové počty publikací'},
            xaxis: {
                categories: []
            }, 
            chart: { stacked: true }, 
            plotOptions: { bar: { horizontal: true } }, 
            legend: {show: false}
        },
        lineChartOpts: {
			chart: {
				id: "chart"
			},
			xaxis: {
				categories: []
			}
		},
		chartSeries: [{
			data: []
      	}]
    }),
    watch: {
        stats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        this.prep();
    },
    methods: {
        prep: function() {
            
            let xaxis = [];
            let data = [];
            
            if(this.stats) {
                console.log("graph", this.stats, Object.entries(this.stats))
                Object.entries(this.stats).forEach( ([v, i]) => {
                    xaxis.push(v);
                    data.push(i);
                });
            }
            
            console.log("graph", xaxis, data)
            
            this.height = this.defaultHeight + (xaxis.length * 10)
            this.lineChartOpts = this.lineChartOptsDefault
            this.lineChartOpts.xaxis.categories = xaxis
            this.chartSeries = [{data: data}];

        }
    }

}
</script>
