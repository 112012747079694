<template>
	<span>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('view.publication-authors') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="editable && authors.length > 1" @click="contributionModalOpen">
                {{ $t('btn.set-author-contributions') }} <v-icon>mdi-account-switch</v-icon>
            </v-btn>
            <v-btn v-if="editable" color="primary" @click="addNewAuthor" class="ml-2">
                {{ $t('btn.add-author') }} <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
		<v-simple-table>
			<template v-slot:default>
				<thead>
                    <tr>
                        <th>{{$t("general.order")}}</th>
                        <th>{{$t("persons.displayName")}}</th>
                        <th>{{$t("persons.type")}}</th>
                        <th>{{ $t('publication.authors.reprint') }}</th>
                        <th>{{ $t('publication.authors.contribution') }}</th>
                        <th>{{ $t('publications.authors.department') }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(author, index) in authors" :key="getUserId(author)">
                        <td>{{index+1}}</td>
                        <td>
                            <author-name :editable="editable" :author="author"></author-name>
                        </td>
                        <td>
                            <v-chip v-if="author.type==1" small >{{$t("persons.internal")}}</v-chip>
                            <v-chip v-if="author.type==2 && author.externsl == true" small>{{$t("persons.external")}}</v-chip>
                            <v-chip v-if="author.type==2 && author.external == false" small>{{ $t("persons.external") }} / {{$t("persons.internal")}}</v-chip>
                            <v-chip v-if="author.foreignAuthor" class="ml-1" small>{{ $t("persons.foreign") }}</v-chip>
                        </td>
                        <td>
                            <v-switch :disabled="!editable" v-model="author.correspondenceAuthor" ></v-switch>
                        </td>
                        <td>
                            {{ author.contribution }}
                        </td>
                        <td>
                            <AuthorDepartment v-if="hasSelectableDepartment(author)" :editable="editable" :author="author"></AuthorDepartment>
                        </td>
                        <td>
                            <v-btn icon v-if="editable && index > 0" @click="moveUp(author)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                        </td>
                        <td>
                            <v-btn icon v-if="editable && index != (authors.length-1)" @click="moveDown(author)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                        </td>
                        <td>
                            <v-btn v-if="editable" text icon @click="change(author)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn v-if="editable" text icon @click="remove(author)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
			</template>
		</v-simple-table>
        <AuthorModal ref="authorModal" @close-dialog="modalClosed"></AuthorModal>
        <AuthorContributionModal :authors="authors" ref="authorContributionModal"></AuthorContributionModal>
	</span>
</template>

<script>
import DepartmentSearchModal from '../dialogs/DepartmentSearchModal.vue';
import AuthorModal from '../dialogs/AuthorModal.vue';
import AuthorName from '../parts/AuthorName.vue';
import AuthorDepartment from '../parts/AuthorDepartment.vue';
import AuthorContributionModal from '../dialogs/AuthorContributionModal.vue';
import { useNotificationStore } from '../../stores/notification-store';

export default {
    name: "PublicationAuthorsManager",
    props: {
        errorMessages: Array,
		valueText: String,
        value: Number,
		authors: Array,
        editable: Boolean,
    },
    data: function () {
        return {
            timeoutId: null,
            search: "",
            isLoading: false,
            hasValue: false,
			foundItem: {},
            notifyService: useNotificationStore(),
            changeIndex: -1,
			rules: {
				select: [
					v => !!v || 'Zadejte hledanou hodnotu',
				]
			}
        };
    },
    methods: {
        hasSelectableDepartment: function(author) {
            return author.type == 1 || (author.type == 2 && !author.external)
        },
        clear: function () {
            this.hasValue = false;
        },
		modalOpen: function() {
			this.$refs.search.open()
		},
		modalClosed: function(response) {
            this.foundItem = response;
			this.updateValue()
		},
        contributionModalOpen: function() {
            this.$refs.authorContributionModal.open()
        },
        updateValue: function () {
            this.$emit("input", this.foundItem);
        },
        updateOrder: function() {
            this.authors.forEach( (i, index) => { i.priority = index; })
        }, 
        moveUp: function(author) {
            let index = this.authors.findIndex( a => this.getUserId(a) == this.getUserId(author)  )
            if(index > 0) {
                let rows = [ this.authors[index-1], this.authors[index] ]
                this.authors.splice( index-1, 2, rows[1], rows[0] )
                this.updateOrder()
            }
        },
        moveDown: function(author) {
            let index = this.authors.findIndex( a => this.getUserId(a) == this.getUserId(author)  )
            if( (index+1) < this.authors.length ) {
                let rows = [ this.authors[index], this.authors[index+1] ]
                this.authors.splice( index, 2, rows[1], rows[0] )
                this.updateOrder()
            }
        },
        change: function( author ) {
            let index = this.authors.findIndex( a => this.getUserId(a) == this.getUserId(author) );
            this.changeIndex = index;
            console.log(this.changeIndex);
            this.authorModalOpen();
        },
        remove: function(author) {
            console.log(author)
            let index = this.authors.findIndex( a => this.getUserId(a) == this.getUserId(author) )
            if(this.authors.length > 1) {
                if(index >= 0) {
                    this.authors.splice( index, 1 )
                    this.updateOrder()
                }
            } else {
                this.notifyService.addError({message: this.$t('messages.unable-to-remove-last-author')})
            }
        },
        addNewAuthor: function() {
            this.changeIndex = -1;
            this.authorModalOpen();
        },
        authorModalOpen: function() {
            this.$refs.authorModal.open()
        },
        getUserId: function(a) {
            if(a.type==2) {
                return a.externalAuthorId;
            }
            return a.uic;
        },
		modalClosed: function(response) {
			console.log(response)
            let person = this.authors.findIndex( a => this.getUserId(a) == this.getUserId(response) );
            console.log(person)
            if(person < 0) {
                response.priority = this.authors.length
                response.baseUnitId = 0;
                response.baseUnitAbbr = null;
                response.baseUnit = null;
                console.log(response)

                if(this.changeIndex >= 0) {
                    let tmpAuthor = this.authors[this.changeIndex];

                    response.contribution = tmpAuthor.contribution;
                    response.priority = this.changeIndex;
                    this.authors.splice( this.changeIndex, 1, response );
                    this.changeIndex = -1;
                } else {
                    this.authors.push(response)
                }
            } else {
                this.notifyService.addError({message: this.$t('messages.unable-to-add-same-author')})
            }
		},
    },
    components: { DepartmentSearchModal, AuthorModal, AuthorName, AuthorDepartment, AuthorContributionModal }
}
</script>