<template>
    <v-combobox :label="label" append-icon="" chips multiple v-model="tags" readonly>
        <template v-slot:selection="data">
            <v-chip :key="data.item.id" small :color="tagColor(data.item)">
                <v-icon class="mr-1" small>{{ data.item.icon }}</v-icon>
                {{ data.item.name }}
                &nbsp; <v-icon v-if="data.item.locked" class="mr-1" small>mdi-lock</v-icon>
                <v-icon v-if="data.item.system" class="mr-1" small>mdi-wrench</v-icon>
            </v-chip>
        </template>
    </v-combobox>
</template>

<script>
import { authStore } from '@/stores/user'
import PublicationTagIcon from './PublicationTagIcon.vue';
import emitter from '../../plugins/mitt';

export default {
    name: "PublicationTagCombo",
    props: {
        tags: {},
        label: "",
    },
    data: function () {
        return {
            color: "",
            icon: "",
            userStore: authStore(),
            enabledTags: ["checked", "check.requested", "check.valid", "check.invalid"]
        };
    },
    computed: {
        pubTags() {
            return this.tags.filter(i => this.enabledTags.includes(i.code) ).map(f => {
                f.own = (f.ownerUic == this.userStore.uic);
                return f;
            })
        },
    },
    mounted: function () {
    },
    methods: {
        tagIcon: function (item) {
            switch (item.code) {
                case "checked":
                    return "mdi-tag-check";
            }
        },
        tagColor: function (item) {
            switch (item.code) {
                case "checked":
                    return "green";
                default:
                    return item.color;
            }
        },
        showPublicationTags: function() {
            emitter.emit( "publication.tags.dialog", {publicationId: this.publicationId, dialogPersistent: false} )
        }
    },
    components: { PublicationTagIcon }
}
</script>