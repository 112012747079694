<template>
	<v-alert v-if="show" :type="barType">
		<v-row>
			<v-col class="grow">
				{{ message }}
			</v-col>
			<v-spacer></v-spacer>
			<v-col>
				<v-btn @click="openCheckLog()" v-if="hasResults" small dark color="secondary">Zobrazit výsledek kontroly</v-btn>
			</v-col>
		</v-row>
	</v-alert>
</template>

<script>
import emitter from '../../plugins/mitt';

export default {
    name: "PublicationCheckerBar",
    props: {
		publication: {
			type: Object,
			required: true,
		}
    },
    data: function () {
        return {
			show: false,
			barType: "info",
			message: "",
			hasResults: false,
        };
    },
	mounted: function() {
		this.process()
	},
	watch: {
        publication: function(nV,oV) {
            this.process();
        }
    },
    methods: {
		openCheckLog: function() {
			emitter.emit("publication.check.dialog", {publicationId: this.publication.id, dialogPersist: false} )
		},
		process: function() {
			this.show = false;
			this.hasResults = false;

			if(!this.publication || !this.publication.tags) {
				return;
			}

			let tags = this.publication.tags;

			let checkTag = tags.find( f => f.code == "check.requested" || f.code == "check.valid" || f.code == "check.invalid" );
			console.log("xxx", checkTag);
			if(checkTag) {
				this.show = true;

				switch(checkTag.code) {
					case "check.valid":
						this.barType = "success"
						this.message = this.$t('messages.check-valid')
						this.hasResults = true;
						break;
					case "check.invalid":
						this.barType = "warning"
						this.message = this.$t('messages.check-invalid')
						this.hasResults = true;
						break;
					default:
						this.barType = "info"
						this.message = this.$t('messages.check-requested')
						
					}
				console.log(this.message)
			}
		},
    },
}
</script>