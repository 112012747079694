<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="800px"
        @keydown.esc="dialogOpen = false"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary" class="flex-grow-0">
                <v-toolbar-title>{{panelTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogOpen = false"><v-icon>mdi-close</v-icon></v-btn>

                <template v-slot:extension>
                    <v-tabs v-model="tab" align-with-title>
                        <v-tabs-slider color="yellow"></v-tabs-slider>
                        <v-tab color="white">{{ $t('btn.searchAuthor') }}</v-tab>
                        <v-tab color="white" @click="createAuthor">{{ $t('btn.create-external-author') }}</v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card-text>
                        <v-form @submit.prevent="search()">
                        <v-row class="mt-2">
                            <v-col cols="4">
                                <v-select
                                    label="Typ autora" 
                                    v-model="q.authorType"
                                    :items="authorTypes"
                                    required
                                    >
                                </v-select>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="q.name" :label="$t('labels.search-by-name-or-uic')" 
                                @click:clear="clear()"
                                @keydown.enter="search()"
                                ref="searchInput"
                                autofocus
                                clearable 
                                :disabled="isLoading"
                                :loading="isLoading"
                                required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <v-text-field v-model="q.orcId" label="ORCID" 
                                required></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="q.scopusId" label="ScopusID" 
                                required></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="q.researcherId" label="ResearcherId" 
                                required></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-btn depressed @click="search()">{{ $t('btn.search') }} <v-icon right>mdi-magnify</v-icon></v-btn>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-card-text>
                    <v-expand-transition>
                        <v-card-text v-if="searchHasData">
                            <v-row>
                                <v-col v-if="personStore.persons.length > 0">
                                    <v-subheader>{{ $t('view.internal-authors') }}</v-subheader>
                                    <v-simple-table height="500">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('authors.name-and-surname') }}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="person in personStore.persons">
                                                    <td>{{person.displayName}}</td>
                                                    <td> <author-departments-chips :author="person"></author-departments-chips> </td>
                                                    <td>
                                                        <v-btn @click="pick(person)" text small icon>{{ $t('btn.pick') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <v-col v-if="externalPersonStore.persons.length > 0">
                                    <v-subheader>{{ $t('views.external-authors') }}</v-subheader>
                                    <v-simple-table height="500">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('authors.name-and-surname') }}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="person in externalPersonStore.persons">
                                                    <td>{{person.displayName}}</td>
                                                    <td> <author-departments-chips :author="person"></author-departments-chips> </td>
                                                    <td>
                                                        <v-btn @click="pickExternal(person)" text small icon>{{ $t('btn.pick') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-expand-transition>
                    <v-card-actions>
                        <v-btn color="error" @click="dialogOpen = false">{{ $t('btn.close') }}</v-btn>
                    </v-card-actions>
                </v-tab-item>
                <v-tab-item>
                    <v-card-text>
                        <v-alert text type="warning">{{ $t('messages.search-for-external-author-first') }}</v-alert>
                        <v-alert text type="info">{{ $t('messages.external-author-guide') }}</v-alert>
                        <v-form>
                        <v-row class="mt-2">
                            <v-col cols="6">
                                <v-text-field v-model="extAuthor.surname" :label="$t('authors.surname')" required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="extAuthor.name" :label="$t('authors.name')" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col cols="4">
                                <v-text-field v-model="extAuthor.orcId" label="ORCID"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="extAuthor.scopusId" label="Scopus ID"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="extAuthor.researcherId" label="Researcher ID"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-col cols="6">
                                <v-switch v-model="extAuthor.foreignAuthor" label="$t('persons.foreign')" required></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn @click="createExternalAuthor" color="success">{{ $t('btn.create-external-author') }}</v-btn>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-card-text>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import { usePersonStore } from '../../stores/person-store';
import { useExternalPersonStore } from '../../stores/external-person-store';
import { useNotificationStore } from '../../stores/notification-store';
import AuthorDepartmentsChips from '../parts/AuthorDepartmentsChips.vue';


export default {
  components: { AuthorDepartmentsChips },
    name: 'AuthorModal',
    props: {
        panelTitle: {
            type: String,
            default: "Vyhledat autora",
        },
        preselectedUIC: Number,
    },
    data: () => ({
        q: {},
        name: null,
        authorType: null,
        dialogOpen: false,
        personStore: usePersonStore(),
        externalPersonStore: useExternalPersonStore(),
        notificationManager: useNotificationStore(),
        isLoading: false,
        searchHasData: false,
        researcher: null,
        orcid: null,
        scopus: null,
        authorTypes: [
            {value: 0, text: "-- nezáleží --"},
            {value: 1, text: "Interní autor"},
            {value: 2, text: "Externí autor"},
//            {value: 10, text: "Studenti - aktivní"},
//            {value: 11, text: "Studenti - absolventi"},
        ],
        extAuthor: {

        },
        tab: null
    }),
    mounted: function() {
        this.authorType = 0
        this.extAuthor = {}
    },
    methods: {
        search: function() {
            let search = this.q.name

            if(search.length >= 3 || this.q.orcId.length > 0 || this.q.scopusId.length >0 ) {
                this.isLoading = true

                if(this.authorType == 0 || this.authorType == 1 || this.authorType == 10 || this.authorType == 11) {
                    this.personStore.fetch({q: this.q.name, ...this.q}, () => {this.isLoading=false})
                    this.searchHasData =  true
                }

                if(this.authorType == 0 || this.authorType == 2) {
                    this.externalPersonStore.fetch({q: this.q.name}, () => {this.isLoading=false})
                    this.searchHasData =  true
                }
            }
        },
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true

            if(!props) {return;}

            if(props.search != "") {
                this.q.name = props.search
            }

            if(props.extAuthor) {
                this.extAuthor = {...props.extAuthor}
            }
            
            if(props.autoSearch) {
                this.$nextTick( () => {this.search()})
            }
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        pick: function(person) {
            console.log("picker person", person)
            person.type = 1
            this.close(person)
        },
        pickExternal: function(person) {
            console.log("picker ext person", person)
            person.type = 2
            person.externalAuthorId = person.id
            this.close(person)
        },
        clear: function() {
            this.name = "";
            this.extAuthor = {};
            this.personStore.clearList()
        },

        createAuthor: function() {
            //this.extAuthor = {}
        }, 
        createExternalAuthor: function() {
            this.externalPersonStore.create( {}, this.extAuthor )
                .then(p => {
                    this.notificationManager.addSuccess({message: this.$t('messages.externa-author-created')})
                    
                    let createdAuthor = p.data;

                    if(createdAuthor.id > 0) {
                        createdAuthor.type = 2
                        createdAuthor.uic = createdAuthor.id
                        createdAuthor.externalAuthorId = createdAuthor.id
                        this.pickExternal(createdAuthor)
                    }
                })
                .catch(m => {
                    this.notificationManager.addError({message: this.$t('messages.errors.exsternal-author-was-not-created')})
                })
        },

        searchExternal: function() {
            
        }

    }

}
</script>
