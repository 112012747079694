<template>
    <div style="max-width: 100% !important;">
        <v-skeleton-loader
            :loading="loading"
            type="card"
        >
            <apexchart style="width: 100% !important;" :height="height"  type="bar" :options="lineChartOpts" :series="chartSeries"></apexchart>
        </v-skeleton-loader>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { listsStore } from '../../stores/lists-store';

export default {
    name: 'RootFordCategoriesGraph',
    props: {
        stats: Object,
        loading: Boolean
    },
    data: () => ({
        panelTitle: "",
        show: true,
        lists: listsStore(),

        defaultHeight: 200,
        height: 200,

        lineChartOptsDefault: {
            title: {text: 'FORD kategorie'},
            xaxis: {
                categories: []
            }, 
            chart: { stacked: true }, 
            plotOptions: { bar: { horizontal: true } }, 
            legend: {show: false}
        },
        lineChartOpts: {
			chart: {
				id: "chart"
			},
			xaxis: {
				categories: []
			}
		},
		chartSeries: [{
			data: []
        }]
    }),
    watch: {
        stats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        this.prep();
    },
    methods: {
        prep: function() {
            
            let xaxis = [];
            let series = [];
            
            if(this.stats) {
                // extract types 
                let types = [];
                let typeNames = [];
                Object.entries( this.stats ).forEach( ([y, q]) => {

                    if(xaxis.indexOf(y) == -1) {
                        xaxis.push(y);
                    }

                    Object.entries(q).forEach( ([e,v]) => {
                        console.log("qqq", q, e)
                        if( types.indexOf(e) == -1) {
                            types.push(e);
                        }
                    } )
                } )
            
                console.log("types", types);

                types.forEach( t => {
                    let itemName = t;
                    let itemValues = [];

                    let tmp = {
                        name: itemName,
                        data: []
                    }

                    Object.entries(this.stats).forEach( ([q, x]) => {
                        let tmpVal = 0;
                        if(x.hasOwnProperty(t)) {
                            tmpVal = x[t];
                        } 
                        itemValues.push(tmpVal)
                    } )

                    tmp.data = itemValues;

                    series.push(tmp);
                } )

                console.log("out", series)
            }
            
            //console.log("graph", xaxis, data)
            
            this.height = this.defaultHeight + (xaxis.length * 10)
            this.lineChartOpts = this.lineChartOptsDefault
            this.lineChartOpts.xaxis.categories = xaxis
            this.chartSeries = series;

        }
    }

}
</script>
