<template>
    <v-container fluid>
        <v-toolbar flat>
            <v-toolbar-title class="text-subtitle-2" v-if="title != ''">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="editable" class="primary" @click="openModal()">Nastavit</v-btn>
        </v-toolbar>
        <v-list dense>
            <v-list-item v-for="area in areas" :key="area.id">
                <v-list-item-icon>
                        <v-avatar color="primary" size="36">
                            <span class="white--text">{{ area.code }}</span>
                        </v-avatar>
                    </v-list-item-icon>
                <v-list-item-content>
                    {{ area.name }}
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-alert v-if="areas.length == 0" type="info" outlined dense>
            Publikace nemá evidovány žádné oblasti vzdělávání
        </v-alert>
        <PublicationAreaOfEducationModal ref="areas" @close-dialog="modalClosed"></PublicationAreaOfEducationModal>
    </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import AreaOfEducationService from '../../services/area-of-education-service';
import PublicationAreaOfEducationModal from '../dialogs/PublicationAreaOfEducationModal.vue';

export default {
    name: "PublicationAreaOfEducation",
    props: {
        publication: {
            type: Object,
			required: true,
        },
        title: {
            type: String,
            default: "Oblasti vzdělávání",
            required: false,
        },
        editable: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data: () => ({
        kwords: [],
        publicationService: new AreaOfEducationService(),
        areas: [],
    }),
	watch: {
		publication: function(nVal, oVal) {
			if(nVal != oVal) {
				this.load();
			}
		}
	},
	mounted: function() {
        this.load()
	},
    methods: {
        load: function() {
            console.log("Loading expenses")
            if(this.publication && this.publication.id > 0) {
                this.publicationService.fetchAreas( this.publication.id )
                    .then(r => {
                        this.areas = r.data.data;
                    })
            }
        },
        openModal: function(ex) {
            this.$refs.areas.open( {publicationId: this.publication.id, selected: this.areas} );
        },
        modalClosed: function(resp) {
            if(resp && resp.reload && resp.reload == true) {
                this.load();
            }
        }
    },
    components: {PublicationAreaOfEducationModal}
}
</script>