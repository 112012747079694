<template>
 <v-row>
            <v-col cols="8">
                <v-card-title>Publikace autorů</v-card-title>

                <v-data-table :headers="authorHeaders" :items="authorStats" 
                    :items-per-page="50"
                    show-expand
                    :expanded.sync="expanded"
                    item-key="author.uic"
                    >
                    <template v-slot:item.author="{item}">
                        {{ item.author.displayName }}
                    </template>
                    <!--
                    <template v-slot:item.approved="{item}">
                        <v-chip color="green">{{ item.total.noOfApproved }}</v-chip>
                    </template>
                    <template v-slot:item.rejected="{item}">
                        <v-chip color="red" v-if="item.total.noOfRejected > 0">{{ item.total.noOfRejected }}</v-chip>
                    </template>
                    <template v-slot:item.pending="{item}">
                        <v-chip color="orange" v-if="item.total.noOfPending > 0">{{ item.total.noOfPending }}</v-chip>
                    </template>
                    <template v-slot:item.totalCount="{item}">
                        {{ item.total.totalCount }}
                    </template>
                    <template v-slot:item.points="{item}">
                        <span v-if="item.total.pointsPending > 0"><v-chip color="orange" small >{{ item.total.pointsPending.toFixed(2) }}</v-chip> + </span>
                        <v-chip color="green" small>{{ item.total.points.toFixed(2) }}</v-chip>
                    </template>
                    -->	
                    <template v-slot:expanded-item="{headers, item}">
                        <td :colspan="headers.length" elevation="0" flat>
                            <v-sheet  class="pt-2 pb-2" flat>
                                <div v-for="pubStat, k in item.publications" :key="k">
                                    <div class="text-subheader-2">{{ k }}</div>
                                    <v-row v-for="yStat in pubStat" :key="yStat.publicationTypeId">
                                        <v-col cols="10">{{ yStat.publicationType }}</v-col>
                                        <v-col> <v-chip small>{{ yStat.totalCount }}</v-chip></v-col>
                                    </v-row>
                                </div>
                            </v-sheet>
                        </td>
                    </template>
                    
                </v-data-table>

            </v-col>
            <v-col cols="4">
                <TopAuthorsReport title="Nejvíce publikující autoři" :loading="loading" :stats="groupStats.topAuthors" :authors="authorGroupStats" type="total"></TopAuthorsReport>
                <TopAuthorsReport title="Autoři s nejlepšími články" :loading="loading" :stats="groupStats.topScoreAuthors" :authors="authorGroupStats" type="score"></TopAuthorsReport>
                <TopAuthorsReport title="Nejčastější první autoři" :loading="loading" :stats="groupStats.topFirstAuthors" :authors="authorGroupStats" type="first"></TopAuthorsReport>
            </v-col>
        </v-row>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { listsStore } from '../../stores/lists-store';
import TopAuthorsReport from './TopAuthorsReport.vue';

export default {
    name: 'AuthorsModuleDashboard',
    components: {
        TopAuthorsReport
    },
    props: {
        authorStats: Array,
        groupStats: Object,
        authorGroupStats: Array,
        years: Array,
        loading: Boolean
    },
    data: () => ({
        panelTitle: "",
        show: true,
        expanded: [],
        lists: listsStore(),
        authorHeaders: [
			{text: "#", value: 'author.uic'},
			{text: "Autor", value: 'author.displayName'},
			{text: "Celkem", value: 'total.totalCount'},
			{text: "Body", value: 'points'},
		],
    }),
    watch: {
        authorStats: function(o,n) {
            this.prep();
        }
    },
    mounted: function() {
        
    },
    methods: {
        genHeaders: function(years) {
			this.authorHeaders = [];
			this.authorHeaders.push({text: "#", value: 'author.uic'});
			this.authorHeaders.push({text: "Autor", value: 'author.displayName'});

			years.forEach( q => {
				this.authorHeaders.push({text: q, value: `total.${q}.totalCount`});

			} )
		},

        prep: function() {
            this.genHeaders(this.years)
        }  
    }

}
</script>
