var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4 ma-4"},[_c('v-card-title',[_vm._v("Detail útvaru")]),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('DepartmentPicker',{staticClass:"mx-2",attrs:{"value":_vm.baseUnit.id,"value-text":_vm.baseUnit.name},on:{"input":_vm.unitPicked}}),_c('v-select',{staticClass:"mx-2",attrs:{"items":_vm.listsStore.yearList,"label":"Rok od","required":""},model:{value:(_vm.viewYearFrom),callback:function ($$v) {_vm.viewYearFrom=$$v},expression:"viewYearFrom"}}),_c('v-select',{staticClass:"mx-2",attrs:{"items":_vm.listsStore.yearList,"label":"Rok do","required":""},model:{value:(_vm.viewYearTo),callback:function ($$v) {_vm.viewYearTo=$$v},expression:"viewYearTo"}}),_c('v-btn',{staticClass:"mx-2",on:{"click":function($event){return _vm.loadDepartment()}}},[_vm._v("Zobrazit")]),_c('v-spacer'),_c('reload-button-component',{staticClass:"mx-2",on:{"reload":function($event){return _vm.load()}}})],1),(_vm.unitLoaded)?_c('v-card-text',[_c('v-divider'),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Název útvaru","readonly":""},model:{value:(_vm.baseUnit.name),callback:function ($$v) {_vm.$set(_vm.baseUnit, "name", $$v)},expression:"baseUnit.name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Zkratka útvaru","readonly":""},model:{value:(_vm.baseUnit.abbr),callback:function ($$v) {_vm.$set(_vm.baseUnit, "abbr", $$v)},expression:"baseUnit.abbr"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Kód útvaru","readonly":""},model:{value:(_vm.baseUnit.code),callback:function ($$v) {_vm.$set(_vm.baseUnit, "code", $$v)},expression:"baseUnit.code"}})],1),_c('v-col',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"primary":""}},'v-btn',attrs,false),on),[_vm._v("Export")])]}}],null,false,1155322481)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.download('text')}}},[_c('v-list-item-title',[_vm._v("Uložit jako CSV")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.download('xlsx')}}},[_c('v-list-item-title',[_vm._v("Uložit jako Excel (XLSX)")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.exportCitations()}}},[_c('v-list-item-title',[_vm._v("Exportovat citace (Word)")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.exportPublications()}}},[_c('v-list-item-title',[_vm._v("Exportovat publikace (XLSX)")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card-title',[_vm._v("Publikace autorů v roce "+_vm._s(_vm.viewYearFrom))]),_c('v-data-table',{attrs:{"headers":_vm.authorHeaders,"items":_vm.authorStats,"items-per-page":50,"show-expand":"","expanded":_vm.expanded,"item-key":"author.uic"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.displayName)+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length,"elevation":"0","flat":""}},[_c('v-sheet',{staticClass:"pt-2 pb-2",attrs:{"flat":""}},_vm._l((item.publications),function(pubStat,k){return _c('div',{key:k},[_c('div',{staticClass:"text-subheader-2"},[_vm._v(_vm._s(k))]),_vm._l((pubStat),function(yStat){return _c('v-row',{key:yStat.publicationTypeId},[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(yStat.publicationType))]),_c('v-col',[_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(yStat.totalCount))])],1)],1)})],2)}),0)],1)]}}],null,false,2057261821)})],1),_c('v-col',[_c('v-card-title',[_vm._v("Typy publikací v roce "+_vm._s(_vm.viewYearFrom))]),_vm._l((_vm.pubStats),function(pubStat){return _c('v-row',{key:pubStat.publicationTypeId},[_c('v-col',{attrs:{"cols":"8"}},[_vm._v(_vm._s(pubStat.publicationType))]),_c('v-col',[_c('v-chip',[_vm._v(_vm._s(pubStat.totalCount))])],1)],1)})],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }