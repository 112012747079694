<template>
    <v-dialog
        :value="dialogOpen"
        fullscreen
        scrollable
        hide-overlay
        transition="dialog-bottom-transition"
        >
        <v-card tile>
            <v-toolbar dark flat color="primary" class="flex-grow-0">
                <v-toolbar-title>{{panelTitle}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form @submit.prevent="search()">
                <v-row class="mt-2">
                    <v-col cols="8">
                        <v-text-field v-model="name" label="Název grantu/Číslo" 
                        @click:clear="clear()"
                        ref="searchInput"
                        autofocus
			            clearable 
                        :disabled="isLoading"
                        :loading="isLoading"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-select label="Rok" 
                        :items="years"
                        v-model="year"
                        :disabled="isLoading"
                        required></v-select>
                    </v-col>
                    <v-col>
                        <v-btn icon @click="search()"><v-icon>mdi-magnify</v-icon></v-btn>
                    </v-col>
                </v-row>
                </v-form>

                <v-expand-transition  v-if="grantStore.items.length > 0">
                    <div>
                    <v-divider></v-divider>
                    <v-simple-table fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Název</th>
                                    <th>Číslo</th>
                                    <th>Interní číslo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in grantStore.items">
                                    <td>{{item.name}}</td>
                                    <td>{{item.number}}</td>
                                    <td>{{item.internalNumber}}</td>
                                    <td>
                                        <v-btn @click="pick(item)" small icon><v-icon>mdi-arrow-right</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    </div>
                </v-expand-transition>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">Zavřít</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { useGrantStore } from '../../stores/grant-store'



export default {
    name: 'GrantSearchModal',
    props: {
        panelTitle: {
            type: String,
            default: "Vyhledat grant",
        },
        preselectedUIC: Number,
    },
    data: () => ({
        name: null,
        year: 2024,
        dialogOpen: false,
        grantStore: useGrantStore(),
        isLoading: false,
        years: [ 2019, 2020, 2021, 2022, 2023, 2024, 2025 ],
    }),
    mounted: function() {
    },
    methods: {
        search: function() {
            let search = this.name

            if(search.length >= 3) {
                this.isLoading = true
                this.grantStore.fetch({q: search, year: this.year}, () => {this.isLoading=false})
            }
        },
        open: function(props) {
            this.dialogOpen = true

            if(!props) {return;}

            if(props.search != "") {
                this.name = props.search
            }

            if(props.autoSearch) {
                this.$nextTick( () => {this.search()})
            }
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        pick: function(item) {
            this.close(item)
        },
        clear: function() {
            this.name = "";
            this.grantStore.clearList()
        }
    }

}
</script>
