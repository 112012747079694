<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialogOpen"
            :persistent="persistent"
            max-width="700px"
            @keydown.esc="dialogOpen = false"
            >
            <v-card tile>
                <v-card-title>Oblasti vzdělávání publikace</v-card-title>
                <v-card-text>
                    <v-combobox :items="store.items" v-model="selected" item-value="id" item-text="name" multiple label="Oblasti vzdělávání" chips></v-combobox>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="save()">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import emitter from '../../plugins/mitt';
import AreaOfEducationService from '../../services/area-of-education-service';
import { useAreaOfEducationStore } from '../../stores/area-of-education-store';

export default {
    name: 'PublicationAreaOfEducationDialog',
    props: {},
    data: () => ({
        dialogOpen: false,
        persistent: true,
        publicationId: {},
        service: new AreaOfEducationService(),
        store: useAreaOfEducationStore(),
        items: [],
        selected: [],
        current: [],
    }),
    mounted: function () {
        this.dialogOpen = false;

        /*
        emitter.on("publication.areasOfEducation.dialog", f => {
            console.log("event received", f);
            this.open( f )
        })
        */

        if(!this.store.items || this.store.items.length == 0)  {
            this.store.fetch();
        }
    },
    methods: {
        open: function(props) {
            this.dialogOpen = true
            
            if(!props) {return;}

            if("dialogPersistent" in props) {
                this.persistent = props.dialogPersistent;
            }

            this.publicationId = props.publicationId;

            if("selected" in props && props.selected && props.selected.length > 0) {
                this.current = props.selected;
                this.selected = props.selected.map( q => q );
            }
        }, 
        save: async function() {
            if(this.selected.length > 0) {
                console.log("1")
                await this.selected.forEach( async a =>  {
                    await this.service.assign( this.publicationId, a.id )
                });
            }

            // remove removed :)
            if( this.current.length > 0 ) {
                let toRemove = this.current.filter( i => {
                    if( this.selected.find( q => q.id == i.id ) ) {
                        return;
                    } 
                    return i;
                } )

                await toRemove.forEach( async z => {
                    await this.service.unassign(this.publicationId, z.id)
                } )
            }

            await this.service.fetchAreas(this.publicationId);
            setTimeout(() => {
                this.close({reload: true});
            }, 1000);
        },
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
    },
    components: {  }
}
</script>
