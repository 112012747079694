import { axiosInst } from "../main";
import BaseService from "./base-service";

export default class DepartmentService extends BaseService {
    
    async get( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/departments/${id}`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchAuthors( id, params )  {
        const response = axiosInst.get(`/departments/${id}/authors`, {
            params: params
        })
        
        return response
    }

    async fetchPublicationStatistic( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/departments/${id}/stats/publications`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchDepartmentStatistic( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/departments/${id}/stats/departments`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchAuthorStatistic( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/departments/${id}/stats/authors`, {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchAll( params, loadingCallback )  {
        const response = await axiosInst.get('/departments', {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchForAuthor( uic, params, loadingCallback )  {
        let path = (uic) => `/authors/${uic}/departments`

        const response = await axiosInst.get( path(uic), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchForExternalAuthor( uic, params, loadingCallback )  {
        let path = (uic) => `/external-authors/${uic}/departments`

        const response = await axiosInst.get( path(uic), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    async fetchForFaculty( id, params, loadingCallback )  {
        let path = (id) => `/faculties/${id}/departments`

        const response = await axiosInst.get( path(id), {
            params: params
        })
        
        this.handleCallback(loadingCallback)
        return response
    }

    
    async downloadAuthorStats( id, params, loadingCallback )  {
        const response = await axiosInst.get(`/reports/departments/${id}`, {
            responseType: 'blob',
            params: params
        }).then( (r) => {
            const href = URL.createObjectURL(r.data);
            const link = document.createElement('a');
            link.href= href;
            link.setAttribute('download', `cv-file-department-${id}.${params.format}`)
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href)
        } )
        
        this.handleCallback(loadingCallback)
        return response
    }
}