import { defineStore } from "pinia";
import AreaOfEducationService from "../services/area-of-education-service";

export const useAreaOfEducationStore = defineStore("areaOfEducation", {
    state: () => ({
        items: [],
        searchQuery: {
			name: null,
		},
    }),
    getters: {
        getItems() {
            return this.items
        }
    },
    actions: {
        async fetch(params) {
            let service = new AreaOfEducationService();
            const results = await service.fetchAreaList();
            this.items = results.data.data;
        }
    }
})