<template>
    <v-container fluid v-if="show">
        <v-toolbar flat>
            <v-toolbar-title class="text-subtitle-2" v-if="title != ''">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-slide-group show-arrows>
            <v-slide-item v-for="slide in slides" :key="slide.id">
                <v-card class="px-3 ma-4">
                    <div class="text-overline mt-2 text-center">{{ slide.title }}</div>
                    <div class="text-center">{{ slide.year }}</div>
                    <div class="text-center">{{ slide.category }}</div>
                    <v-divider class="mx-4 mt-2"></v-divider>
                    <div class="text-h4 font-weight-bold text-center mt-4 mb-4">{{ slide.value }}</div>
                </v-card>
            </v-slide-item>
        </v-slide-group>
        <v-list flat v-if="fieldClassifications && fieldClassifications.length > 0">
            <v-subheader class="text-subtitle-2">Klasifikace dle (RIV,CEP)</v-subheader>
                <v-list-item v-for="catItem in fieldClassifications" key="catItem.id">
                    <v-list-item-icon>
                        <v-avatar color="primary" size="36">
                            <span class="white--text">{{ catItem.code }}</span>
                        </v-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{ catItem.name }}
                    </v-list-item-content>
                </v-list-item>
        </v-list>
        <div class="text-subtitle-2"></div>
        <div class="">{{ fieldClassificationLabel }}</div>
    </v-container>
</template>

<script>
import { required } from 'vee-validate/dist/rules';
import PublicationService from '../../services/publication-service'

export default {
    name: "PublicationMetricsList",
    props: {
        publication: {
            type: Object,
			required: true,
        },
        title: {
            type: String,
            default: "Metriky publikace",
            required: false,
        },
        editable: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data: () => ({
        kwords: [],
        publicationService: new PublicationService(),
        metrics: {},
        show: false,
        fieldClassifications: [],
        slides: [],
    }),
	watch: {
		publication: function(nVal, oVal) {
			if(nVal != oVal) {
				this.load();
			}
		}
	},
	mounted: function() {
        this.load()
	},
    methods: {
        load: function() {
            console.log("Loading expenses")
            this.show = false;
            if(this.publication && this.publication.id > 0) {
                this.publicationService.fetchMetrics( this.publication.id )
                    .then(r => {
                        this.metrics = r.data;

                        if(this.metrics.journalMetrics || this.metrics.journalCategoryMetrics ) {
                            this.show = true;
                            this.prepSlides()
                        }

                        if( this.metrics.fieldClassifications || this.metrics.fieldClassifications.length > 0 ) {
                            this.fieldClassifications = this.metrics.fieldClassifications;
                        }
                    })
            }
        },
        prepSlides: function() {
            this.slides = []

            if(this.show) {
                this.slides.push({id: "def-1", title: "Article influence score", category: "_", value: this.metrics.journalMetrics.influenceArticle, year: this.metrics.journalMetrics.year })
                
                if(this.metrics.journalFordMetrics && this.metrics.journalFordMetrics.length > 0 ) {
                    this.metrics.journalFordMetrics.forEach( e => {
                        this.slides.push({id: "def-f1-" + e.id, title: "Article Influence Quartile", category: e.ford + " (FORD) ", value: e.label, year: e.year });
                    } )
                }

                this.metrics.journalCategoryMetrics.forEach( e => {
                    this.slides.push({id: "def-c1-" + e.id, title: "Article Influence Score", category: e.category + " in " + e.categoryEdition, value: e.articleInfluenceRank, year: e.year });
                    this.slides.push({id: "def-c2-" + e.id, title: "Article Influence Quartile", category: e.category + " in " + e.categoryEdition, value: e.articleInfluenceQuartile, year: e.year });
                });


                this.slides.push({id: "def-2", title: "Eigenfactor Score", category: "_", value: this.metrics.journalMetrics.influenceEigenScore, year: this.metrics.journalMetrics.year })
                this.slides.push({id: "def-3", title: "Normalized Eigenfactor", category: "_", value: this.metrics.journalMetrics.influenceEigenNormalized, year: this.metrics.journalMetrics.year })
            }

        }
    },
    components: {}
}
</script>