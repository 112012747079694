var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-4 ma-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.panelTitle))]),_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('PersonPicker',{staticClass:"mx-2",attrs:{"value":_vm.pickedPerson.uic,"value-text":_vm.pickedPerson.displayName},on:{"input":_vm.personPicked}}),_c('DepartmentPicker',{staticClass:"mx-2",attrs:{"value":_vm.baseUnit.id,"value-text":_vm.baseUnit.name},on:{"input":_vm.unitPicked}}),_c('v-select',{staticClass:"mx-2",attrs:{"items":_vm.listsStore.publicationStateList,"label":"Stav publikace","clearable":""},model:{value:(_vm.query.publicationStateId),callback:function ($$v) {_vm.$set(_vm.query, "publicationStateId", $$v)},expression:"query.publicationStateId"}}),_c('v-select',{staticClass:"mx-2",attrs:{"items":_vm.listsStore.yearList,"label":"Rok publikace","required":""},model:{value:(_vm.query.year),callback:function ($$v) {_vm.$set(_vm.query, "year", $$v)},expression:"query.year"}}),_c('v-btn',{staticClass:"mx-2",on:{"click":function($event){return _vm.search()}}},[_vm._v("Hledat")]),_c('v-spacer'),_c('reload-button-component',{staticClass:"mx-2",on:{"reload":function($event){return _vm.load()}}}),_c('v-btn',{on:{"click":function($event){return _vm.downloadCitations()}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.publicationStore.items,"items-per-page":_vm.tableItemsPerPage},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userStore.hasRole(['ADMINISTRATOR']))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removePublication(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-trash-can")])],1):_vm._e(),(false)?_c('v-btn',{attrs:{"icon":"","to":{name: 'edit-publication', params: {id: item.id}}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","to":{name: 'publication-detail', params: {id: item.id, backTo: {name: 'publications'}}}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-right")])],1)]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('publication-state',{attrs:{"stateId":item.publicationStateId,"label":item.publicationState}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.publicationTypeLabelShort))])]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [_c('PublicationTagIcons',{attrs:{"tags":item.tags,"publication-id":item.id}})]}}])})],1),_c('confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }