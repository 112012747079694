<template>
    <v-dialog
        :value="dialogOpen"
        scrollable
        persistent
        max-width="500px"
        >
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-toolbar-title>{{ $t('dialogs.publication-expenses') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form v-model="formValid">
                    <v-text-field required :rules="notEmpty" label="Popis/Poznámka" v-model="expense.label"></v-text-field>
                    <v-switch label="Poplatek nebyl hrazen ČZU" v-model="expense.paidByOther"></v-switch>

                    <v-text-field required :rules="priceRule" label="Částka (CZK)" suffix="CZK" v-model="expense.price"></v-text-field>
                
                <v-row class="mt-0"> 
                    <v-col>
                        <v-text-field :rules="priceRule" :required="!expense.paidByOther" label="Částka v originální měně" v-model="expense.originalPrice"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select  :required="!expense.paidByOther" label="Měna" v-model="expense.originalCurrency" :items="currencies"></v-select>
                    </v-col>
                </v-row>

                <v-text-field :required="!expense.paidByOther" label="Vláček" v-model="expense.identifier"></v-text-field>

                <v-row class="mt-0">
                    <v-col>
                        <v-text-field :rules="monthRule" :required="!expense.paidByOther" label="Měsíc platby" v-model="expense.month"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :rules="yearRule" :required="!expense.paidByOther" label="Rok platby" v-model="expense.year"></v-text-field>
                    </v-col>
                </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="close">{{ $t('btn.close') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" :disabled="!formValid" @click="saveExpense">{{ $t('btn.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import PublicationService from '../../services/publication-service';
import { listsStore } from '../../stores/lists-store';
import { useNotificationStore } from '../../stores/notification-store';
import { authStore } from '../../stores/user';

export default {
    name: 'PublicationExpenseEditModal',
    props: {
        panelTitle: {
            type: String,
            default: "Změnit typ publikace",
        },
    },
    data: () => ({
        name: null,
        dialogOpen: false,
        isLoading: false,
        formValid: false,
        userStore: authStore(),
        notificationService: useNotificationStore(),
        publicationService: new PublicationService(),
        userdata: {},
        currentUser: -1,
        expense: {},
        publicationId: 0,
        currencies: [
            {text: "CZK", value: "CZK"},
            {text: "USD", value: "USD"},
            {text: "EUR", value: "EUR"},
        ],
        notEmpty: [
            v => !!v || 'Položka je vyžadována',
        ],
        numberRules: [
            v => !!v || 'Položka je vyžadována',
            v => v > 0 || 'Položka nesmí být nulová',
        ],
        yearRules: [
            v => !!v || 'Položka je vyžadována',
            v => v > 2020 && v <= 2024 || 'Zadaný rok není platný',
        ],
        monthRules: [
            v => !!v || 'Položka je vyžadována',
            v => (v > 0 && v <= 12) || 'Zadané číslo měsíce není platné',
        ],
        priceRules: [
            v => !!v || 'Položka je vyžadována',
            v => v > 0 || 'Položka nesmí být nulová',
        ],
    }),
    mounted: function() {
        this.dialogOpen = false
    },
    computed: {
        monthRule() {
            const out = []

            if(!this.expense.paidByOther) {
                let rule = v => !!v || 'Položka je vyžadována';
                out.push(rule);
                
                rule = v => (v > 0 && v <= 12) || 'Zadané číslo měsíce není platné';
                out.push(rule);
            }

            return out;
        },
        yearRule() {
            const out = []

            if(!this.expense.paidByOther) {
                let rule = v => !!v || 'Položka je vyžadována';
                out.push(rule);
                
                rule = v => v > 2020 && v <= 2024 || 'Zadaný rok není platný';
                out.push(rule);
            }

            return out;
        },
        priceRule() {
            const out = []

            if(!this.expense.paidByOther) {
                let rule = v => !!v || 'Položka je vyžadována';
                out.push(rule);
                
                rule =  v => v >= 0 || 'Položka nesmí být nulová';
                out.push(rule);
            }

            return out;
        }
    },
    methods: {
        open: function(props) {
            console.log("dialog-open");
            //this.$refs.searchInput.focus()
            this.dialogOpen = true
            
            if(props.expense == null) {
                props.expense = {originalCurrency: "CZK"}
            }

            this.expense = {...props.expense};
            this.publicationId = props.publicationId;

            console.log(props);
        }, 
        close: function( data ) {
            this.dialogOpen = false
            this.$emit("close-dialog", data)
        },
        saveExpense: function() {

            let call;
            if(this.expense.id > 0) {
                call = this.publicationService.updateExpense( this.publicationId, this.expense.id, this.expense );
            } else {
                call = this.publicationService.createExpense( this.publicationId, this.expense )
            }
            
            
            call.then( r => {
                this.notificationService.addSuccess({message: "Záznam přidán/upraven."})
                this.close({reload: true});
            } )
            .catch( ex => {
                this.notificationService.addError({message: "Záznam s výdajem o publikaci se nepodařilo přidat"})
            } )
        },
        clear: function() {
        }
    }

}
</script>
