<template>
    <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
            <v-icon @click="showPublicationTags()"  :color="tagColor(tag)" v-bind="attrs" v-on="on">{{ tagIcon(tag) }}</v-icon>
        </template>
        <span>{{ tag.name }}</span>
    </v-tooltip>
</template>

<script>

export default {
    name: "PublicationTagIcon",
    props: {
        tag: {},
    },
    data: function () {
        return {
            color: "",
            icon: "",
        };
    },
	mounted: function() {
        console.log("tag mounted", this.tag)
	}, 
    methods: {
        //@fixme - called on each mosehover
        tagIcon: function(item) {
            console.log(item)
           return item.icon;
        },
        tagColor: function(item) {
            
            return item.color;
            
            if(!item.own) {
                return "grey";
            }
            
            switch(item.code) {
                case "checked":
                    return "green"
            }

        },
        showPublicationTags: function() {
            this.$emit('click');
        }
    }
}
</script>